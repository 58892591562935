<template>
  <div class="bg-gray-100">
    <div class="mx-auto max-w-2xl px-4 pb-12 pt-12 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">Checkout</h2>

      <div v-if="viewState.loading" >
          <p>Loading...</p>
      </div>

      <div v-if="viewState.standardTicket" class="">
        <div v-if="viewState.event"  class="border-b pb-4">
          <h1 class="text-lg font-medium text-gray-600">
            Order Tickets
          </h1>
          <h2 class="text-4xl font-bold text-gray-900">
            {{ viewState.event.title }}
          </h2>
        </div>

        <div class="mt-6 lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div>
            <div>
              <h2 class="text-lg font-bold text-gray-900">Order contact information</h2>
              <p class="mt-4 text-sm">Please enter the details of the person making the order. This is where any QR code tickets will be sent to.</p>

              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <div>
                  <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                  <div class="mt-1">
                    <input v-model="viewState.orderDetails.firstName" type="text" id="first-name" name="first-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.firstName.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div>
                  <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                  <div class="mt-1">
                    <input v-model="viewState.orderDetails.lastName" type="text" id="last-name" name="last-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.lastName.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label for="email-address" class="block text-sm font-medium text-gray-700">Email addres for ticket delivery</label>
                  <div class="mt-1">
                    <input v-model="viewState.orderDetails.email" type="email" id="email-address" name="email-address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.email.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label for="phone" class="block text-sm font-medium text-gray-700">Phone number</label>
                  <div class="mt-1">
                    <input v-model="viewState.orderDetails.tel" type="text" name="phone" id="phone" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.tel.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div>
                  <label for="country" class="block text-sm font-medium text-gray-700">Country of residence</label>
                  <div class="mt-1">
                    <select v-model="viewState.orderDetails.country" id="country" name="country" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                      <option value="">Please select...</option>
                      <option value="AF">Afghanistan</option>
                      <option value="AL">Albania</option>
                      <option value="DZ">Algeria</option>
                      <option value="AS">American Samoa</option>
                      <option value="AD">Andorra</option>
                      <option value="AO">Angola</option>
                      <option value="AI">Anguilla</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AR">Argentina</option>
                      <option value="AM">Armenia</option>
                      <option value="AW">Aruba</option>
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BS">Bahamas</option>
                      <option value="BH">Bahrain</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BB">Barbados</option>
                      <option value="BY">Belarus</option>
                      <option value="BE">Belgium</option>
                      <option value="BZ">Belize</option>
                      <option value="BJ">Benin</option>
                      <option value="BM">Bermuda</option>
                      <option value="BT">Bhutan</option>
                      <option value="BO">Bolivia (Plurinational State of)</option>
                      <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BW">Botswana</option>
                      <option value="BV">Bouvet Island</option>
                      <option value="BR">Brazil</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="BN">Brunei Darussalam</option>
                      <option value="BG">Bulgaria</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BI">Burundi</option>
                      <option value="CV">Cabo Verde</option>
                      <option value="KH">Cambodia</option>
                      <option value="CM">Cameroon</option>
                      <option value="CA">Canada</option>
                      <option value="KY">Cayman Islands</option>
                      <option value="CF">Central African Republic</option>
                      <option value="TD">Chad</option>
                      <option value="CL">Chile</option>
                      <option value="CN">China</option>
                      <option value="CX">Christmas Island</option>
                      <option value="CC">Cocos (Keeling) Islands</option>
                      <option value="CO">Colombia</option>
                      <option value="KM">Comoros</option>
                      <option value="CG">Congo</option>
                      <option value="CD">Congo (Democratic Republic of the)</option>
                      <option value="CK">Cook Islands</option>
                      <option value="CR">Costa Rica</option>
                      <option value="HR">Croatia</option>
                      <option value="CU">Cuba</option>
                      <option value="CW">Curaçao</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="CI">Côte d'Ivoire</option>
                      <option value="DK">Denmark</option>
                      <option value="DJ">Djibouti</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="EC">Ecuador</option>
                      <option value="EG">Egypt</option>
                      <option value="SV">El Salvador</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="ER">Eritrea</option>
                      <option value="EE">Estonia</option>
                      <option value="SZ">Eswatini (Kingdom of)</option>
                      <option value="ET">Ethiopia</option>
                      <option value="FK">Falkland Islands (Malvinas)</option>
                      <option value="FO">Faroe Islands</option>
                      <option value="FJ">Fiji</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GF">French Guiana</option>
                      <option value="PF">French Polynesia</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="GA">Gabon</option>
                      <option value="GM">Gambia</option>
                      <option value="GE">Georgia</option>
                      <option value="DE">Germany</option>
                      <option value="GH">Ghana</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GR">Greece</option>
                      <option value="GL">Greenland</option>
                      <option value="GD">Grenada</option>
                      <option value="GP">Guadeloupe</option>
                      <option value="GU">Guam</option>
                      <option value="GT">Guatemala</option>
                      <option value="GG">Guernsey</option>
                      <option value="GN">Guinea</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="GY">Guyana</option>
                      <option value="HT">Haiti</option>
                      <option value="HM">Heard Island and McDonald Islands</option>
                      <option value="HN">Honduras</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HU">Hungary</option>
                      <option value="IS">Iceland</option>
                      <option value="IN">India</option>
                      <option value="ID">Indonesia</option>
                      <option value="IR">Iran (Islamic Republic of)</option>
                      <option value="IQ">Iraq</option>
                      <option value="IE">Ireland (Republic of)</option>
                      <option value="IM">Isle of Man</option>
                      <option value="IL">Israel</option>
                      <option value="IT">Italy</option>
                      <option value="JM">Jamaica</option>
                      <option value="JP">Japan</option>
                      <option value="JE">Jersey</option>
                      <option value="JO">Jordan</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="KE">Kenya</option>
                      <option value="KI">Kiribati</option>
                      <option value="KP">Korea (Democratic People's Republic of)</option>
                      <option value="KR">Korea (Republic of)</option>
                      <option value="XK">Kosovo</option>
                      <option value="KW">Kuwait</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="LA">Lao People's Democratic Republic</option>
                      <option value="LV">Latvia</option>
                      <option value="LB">Lebanon</option>
                      <option value="LS">Lesotho</option>
                      <option value="LR">Liberia</option>
                      <option value="LY">Libya</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MO">Macao</option>
                      <option value="MG">Madagascar</option>
                      <option value="MW">Malawi</option>
                      <option value="MY">Malaysia</option>
                      <option value="MV">Maldives</option>
                      <option value="ML">Mali</option>
                      <option value="MT">Malta</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="MQ">Martinique</option>
                      <option value="MR">Mauritania</option>
                      <option value="MU">Mauritius</option>
                      <option value="YT">Mayotte</option>
                      <option value="MX">Mexico</option>
                      <option value="FM">Micronesia (Federated States of)</option>
                      <option value="MD">Moldova (Republic of)</option>
                      <option value="MC">Monaco</option>
                      <option value="MN">Mongolia</option>
                      <option value="ME">Montenegro</option>
                      <option value="MS">Montserrat</option>
                      <option value="MA">Morocco</option>
                      <option value="MZ">Mozambique</option>
                      <option value="MM">Myanmar</option>
                      <option value="NA">Namibia</option>
                      <option value="NR">Nauru</option>
                      <option value="NP">Nepal</option>
                      <option value="NL">Netherlands</option>
                      <option value="NC">New Caledonia</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NI">Nicaragua</option>
                      <option value="NE">Niger</option>
                      <option value="NG">Nigeria</option>
                      <option value="NU">Niue</option>
                      <option value="NF">Norfolk Island</option>
                      <option value="MK">North Macedonia (Republic of)</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="NO">Norway</option>
                      <option value="OM">Oman</option>
                      <option value="PK">Pakistan</option>
                      <option value="PW">Palau</option>
                      <option value="PS">Palestine (State of)</option>
                      <option value="PA">Panama</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="PY">Paraguay</option>
                      <option value="PE">Peru</option>
                      <option value="PH">Philippines</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="QA">Qatar</option>
                      <option value="RO">Romania</option>
                      <option value="RU">Russian Federation</option>
                      <option value="RW">Rwanda</option>
                      <option value="RE">Réunion</option>
                      <option value="BL">Saint Barthélemy</option>
                      <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="MF">Saint Martin (French part)</option>
                      <option value="PM">Saint Pierre and Miquelon</option>
                      <option value="VC">Saint Vincent and the Grenadines</option>
                      <option value="WS">Samoa</option><option value="SM">San Marino</option>
                      <option value="ST">Sao Tome and Principe</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SN">Senegal</option>
                      <option value="RS">Serbia</option>
                      <option value="SC">Seychelles</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SG">Singapore</option>
                      <option value="SX">Sint Maarten (Dutch part)</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="SO">Somalia</option>
                      <option value="ZA">South Africa</option>
                      <option value="GS">South Georgia and the South Sandwich Islands</option>
                      <option value="SS">South Sudan</option>
                      <option value="ES">Spain</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="SD">Sudan</option>
                      <option value="SR">Suriname</option>
                      <option value="SJ">Svalbard and Jan Mayen</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="SY">Syrian Arab Republic</option>
                      <option value="TW">Taiwan, Province of China</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TZ">Tanzania (United Republic of)</option>
                      <option value="TH">Thailand</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TG">Togo</option>
                      <option value="TK">Tokelau</option>
                      <option value="TO">Tonga</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="TN">Tunisia</option>
                      <option value="TR">Turkey</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="TC">Turks and Caicos Islands</option>
                      <option value="TV">Tuvalu</option>
                      <option value="UG">Uganda</option>
                      <option value="UA">Ukraine</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
                      <option value="UM">United States Minor Outlying Islands</option>
                      <option value="US">United States of America</option>
                      <option value="UY">Uruguay</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VU">Vanuatu</option>
                      <option value="VA">Vatican City State</option>
                      <option value="VE">Venezuela (Bolivarian Republic of)</option>
                      <option value="VN">Vietnam</option>
                      <option value="VG">Virgin Islands (British)</option>
                      <option value="VI">Virgin Islands (U.S.)</option>
                      <option value="WF">Wallis and Futuna</option>
                      <option value="EH">Western Sahara</option>
                      <option value="YE">Yemen</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                      <option value="AX">Åland Islands</option>
                    </select>
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.country.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div>
                  <label for="city" class="block text-sm font-medium text-gray-700">Town/City</label>
                  <div class="mt-1">
                    <input v-model="viewState.orderDetails.townCity" type="text" name="city" id="city" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.townCity.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div>
                  <label for="heard-from" class="block text-sm font-medium text-gray-700">Where did you hear about this?</label>
                  <div class="mt-1">
                    <select v-model="viewState.orderDetails.heardFrom" id="heard-from" name="heard-from" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                      <option value="">Please select...</option>
                      <option value="8">Prayer Storm's App</option>
                      <option value="9">Prayer Storm's Mailing List</option>
                      <option value="3">Prayer Storm's Social Media</option>
                      <option value="2">Prayer Storm's Website</option>
                      <option value="4">Other Social Media</option>
                      <option value="5">Church</option>
                      <option value="6">Ministry/Organistion</option>
                      <option value="7">Friend/Family</option>
                      <option value="1">Other</option>
                    </select>
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.heardFrom.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

                <div>
                  <label for="is-partner" class="block text-sm font-medium text-gray-700">Are you a Prayer Strom partner?</label>
                  <div class="mt-1">
                    <select v-model="viewState.orderDetails.isPartner" id="is-partner" name="is-partner" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                      <option value="">Please select...</option>
                      <option value="0">No I'm not a partner</option>
                      <option value="1">Yes I give recurring financial support</option>
                    </select>
                    <div class="text-sm text-red-500" v-for="error of v$.orderDetails.isPartner.$errors" :key="error.$uid">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- <div class="mt-10 border-t border-gray-200 pt-10">
              <fieldset>
                <legend class="text-lg font-medium text-gray-900">Delivery method</legend>
                <RadioGroup v-model="selectedDeliveryMethod" class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <RadioGroupOption as="template" v-for="deliveryMethod in deliveryMethods" :key="deliveryMethod.id" :value="deliveryMethod" :aria-label="deliveryMethod.title" :aria-description="`${deliveryMethod.turnaround} for ${deliveryMethod.price}`" v-slot="{ active, checked }">
                    <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-indigo-500' : '', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
                      <span class="flex flex-1">
                        <span class="flex flex-col">
                          <span class="block text-sm font-medium text-gray-900">{{ deliveryMethod.title }}</span>
                          <span class="mt-1 flex items-center text-sm text-gray-500">{{ deliveryMethod.turnaround }}</span>
                          <span class="mt-6 text-sm font-medium text-gray-900">{{ deliveryMethod.price }}</span>
                        </span>
                      </span>
                      <CheckCircleIcon v-if="checked" class="size-5 text-indigo-600" aria-hidden="true" />
                      <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
                    </div>
                  </RadioGroupOption>
                </RadioGroup>
              </fieldset>
            </div> -->

            <!-- Payment -->
            <!-- <div class="mt-10 border-t border-gray-200 pt-10">
              <h2 class="text-lg font-medium text-gray-900">Payment</h2>

              <fieldset class="mt-4">
                <legend class="sr-only">Payment type</legend>
                <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  <div v-for="(paymentMethod, paymentMethodIdx) in paymentMethods" :key="paymentMethod.id" class="flex items-center">
                    <input v-if="paymentMethodIdx === 0" :id="paymentMethod.id" name="payment-type" type="radio" checked="" class="size-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                    <input v-else :id="paymentMethod.id" name="payment-type" type="radio" class="size-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                    <label :for="paymentMethod.id" class="ml-3 block text-sm font-medium text-gray-700">{{ paymentMethod.title }}</label>
                  </div>
                </div>
              </fieldset>

              <div class="mt-6 grid grid-cols-4 gap-x-4 gap-y-6">
                <div class="col-span-4">
                  <label for="card-number" class="block text-sm font-medium text-gray-700">Card number</label>
                  <div class="mt-1">
                    <input type="text" id="card-number" name="card-number" autocomplete="cc-number" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  </div>
                </div>

                <div class="col-span-4">
                  <label for="name-on-card" class="block text-sm font-medium text-gray-700">Name on card</label>
                  <div class="mt-1">
                    <input type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  </div>
                </div>

                <div class="col-span-3">
                  <label for="expiration-date" class="block text-sm font-medium text-gray-700">Expiration date (MM/YY)</label>
                  <div class="mt-1">
                    <input type="text" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  </div>
                </div>

                <div>
                  <label for="cvc" class="block text-sm font-medium text-gray-700">CVC</label>
                  <div class="mt-1">
                    <input type="text" name="cvc" id="cvc" autocomplete="csc" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <!-- Order summary -->
          <div class="mt-10 lg:mt-0">
            <h2 class="text-lg font-bold text-gray-900">Order tickets</h2>

            <ul role="list" class="divide-y divide-gray-200">
              <li class="flex py-6">
                <div class="flex flex-1 flex-col">
                  <div class="flex">
                    <div class="min-w-0 flex-1">
                      <h4 class="text-sm">
                        <span class="text-lg font-medium text-gray-700 hover:text-gray-800">{{ viewState.standardTicket.title }} Tickets</span>
                      </h4>
                      <p v-if="viewState.standardTicket.description" class="mt-1 text-sm text-gray-500">{{ viewState.standardTicket.description }}</p>
                    </div>

                    <div class="ml-4 flow-root flex-shrink-0">
                      <label for="quantity" class="sr-only">Quantity</label>
                      <div class="relative flex items-center max-w-sm">
                        <button @click.prevent="decreaseStandard()" type="button" id="decrement-button" data-input-counter-decrement="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                          <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                          </svg>
                        </button>
                        <span class="text-gray-900 text-xl mx-4">
                          {{ viewState.standardTicketQuantity }}
                        </span>
                        <button @click.prevent="increaseStandard()" type="button" id="increment-button" data-input-counter-increment="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                          <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="viewState.childTicket" class="flex py-6">
                <div class="flex flex-1 flex-col">
                  <div class="flex">
                    <div class="min-w-0 flex-1">
                      <h4 class="text-sm">
                        <span class="text-lg font-medium text-gray-700 hover:text-gray-800">{{ viewState.childTicket.title }} Tickets</span>
                      </h4>
                      <p v-if="viewState.childTicket.description" class="mt-1 text-sm text-gray-500">{{ viewState.childTicket.description }}</p>
                    </div>

                    <div class="ml-4 flow-root flex-shrink-0">
                      <label for="quantity" class="sr-only">Quantity</label>
                      <div class="relative flex items-center max-w-sm">
                        <button @click.prevent="decreaseChild()" type="button" id="decrement-button" data-input-counter-decrement="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                          <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                          </svg>
                        </button>
                        <span class="text-gray-900 text-xl mx-4">
                          {{ viewState.childTicketQuantity }}
                        </span>
                        <button @click.prevent="increaseChild()" type="button" id="increment-button" data-input-counter-increment="quantity-input" class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                          <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="viewState.childTicket" class="flex pt-6">
                <div>
                  <p v-if="viewState.standardFree > 0" class="mt-2 text-green-500 text-sm font-bold">With {{ viewState.childTicketQuantity }} {{ viewState.childTicket.title }} ticket/s your first {{ viewState.standardFree }} {{ viewState.standardTicket.title }} ticket/s will be free</p>
                  <p v-if="viewState.ratioRequired > 0" class="mt-2 text-red-500 text-sm font-bold">With {{ viewState.childTicketQuantity }} {{ viewState.childTicket.title }} ticket/s you are required to have a minimum of {{ viewState.ratioRequired }} {{ viewState.standardTicket.title }} ticket/s</p>
                  <p class="mt-2 italic text-sm font-bold">For group bookings of more than 10 children please contact us directly via email kids@prayerstorm.org</p>
                  <p class="mt-2 text-red-500 text-sm font-bold">All adults attending the Kids Venue will be required to submit DBS/Indentity/Referee documents prior to attending (or the equivalent of your nationality offender checks and identity documents). These will begin to be processed early 2025.</p>
                </div>
              </li>
            </ul>

            <div class="mt-6 rounded-lg border border-gray-200 bg-white shadow-sm">
              <h3 class="sr-only">Items in your cart</h3>
              <ul role="list" class="divide-y divide-gray-200">
                <li v-for="index in viewState.standardTicketQuantity" :key="index" class="flex px-4 py-6 sm:px-6">
                  <div class="flex flex-1 flex-col">
                    <div class="flex">
                      <div class="min-w-0 flex-1">
                        <div class="flex justify-between">
                          <div>
                            <h4 class="text-lg">
                              <a @click.prevent="" href="#" class="font-bold text-gray-700 hover:text-gray-800">{{ viewState.standardTicket.title }} Ticket #{{ index }}</a>
                            </h4>
                          </div>
                          <div>
                            <p class="mt-1 text-sm font-medium text-gray-900">£{{ stripeUnitAmountString('gbp', viewState.standardTicket.price) }}</p>
                          </div>
                        </div>
                        <div class="mt-2 grid grid-cols-2 gap-x-2 gap-y-2">
                          <div>
                            <label class="block text-sm font-medium text-gray-900">First Name</label>
                            <div class="mt-1">
                              <input type="text" v-model="viewState.delegates[index-1].firstName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm" />
                              <div class="text-sm text-red-500" v-for="error in v$.delegates.$each.$response.$errors[index-1].firstName" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <label class="block text-sm font-medium text-gray-900">Last Name</label>
                            <div class="mt-1">
                              <input type="text" v-model="viewState.delegates[index-1].lastName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm" />
                              <div class="text-sm text-red-500" v-for="error in v$.delegates.$each.$response.$errors[index-1].lastName" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div>
                            </div>
                          </div>

                          <div class="col-span-2">
                            <label class="block text-sm font-medium text-gray-900"> Email</label>
                            <div class="mt-1">
                              <input type="text" v-model="viewState.delegates[index-1].email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm" />
                              <div class="text-sm text-red-500" v-for="error in v$.delegates.$each.$response.$errors[index-1].email" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <div v-if="viewState.childTicket">
                  <li v-for="index in viewState.childTicketQuantity" :key="index" class="flex px-4 py-6 sm:px-6">
                    <div class="flex flex-1 flex-col">
                      <div class="flex">
                        <div class="min-w-0 flex-1">
                          <div class="flex justify-between">
                            <div>
                              <h4 class="text-lg">
                                <a @click.prevent="" href="#" class="font-bold text-gray-700 hover:text-gray-800">{{ viewState.childTicket.title }} Ticket #{{ index }}</a>
                              </h4>
                            </div>
                            <div>
                              <p class="mt-1 text-sm font-medium text-gray-900">£{{ stripeUnitAmountString('gbp', viewState.childTicket.price) }}</p>
                            </div>
                          </div>
                          <div class="mt-2 grid grid-cols-2 gap-x-2 gap-y-2">
                            <div>
                              <label class="block text-sm font-medium text-gray-900">First Name</label>
                              <div class="mt-1">
                                <input type="text" v-model="viewState.childDelegates[index-1].firstName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                <div class="text-sm text-red-500" v-for="error in v$.childDelegates.$each.$response.$errors[index-1].firstName" :key="error">
                                  <div class="error-msg">{{ error.$message }}</div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <label class="block text-sm font-medium text-gray-900">Last Name</label>
                              <div class="mt-1">
                                <input type="text" v-model="viewState.childDelegates[index-1].lastName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                <div class="text-sm text-red-500" v-for="error in v$.childDelegates.$each.$response.$errors[index-1].lastName" :key="error">
                                  <div class="error-msg">{{ error.$message }}</div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <label class="block text-sm font-medium text-gray-900">Gender</label>
                              <div class="mt-1">
                                <select v-model="viewState.childDelegates[index-1].gender" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                  <option value=""></option>
                                  <option value="M">Male</option>
                                  <option value="F">Female</option>
                                </select>
                                <div class="text-sm text-red-500" v-for="error in v$.childDelegates.$each.$response.$errors[index-1].gender" :key="error">
                                  <div class="error-msg">{{ error.$message }}</div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <label class="block text-sm font-medium text-gray-900">Age (Years)</label>
                              <div class="mt-1">
                                <input type="text" v-model="viewState.childDelegates[index-1].age" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                <div class="text-sm text-red-500" v-for="error in v$.childDelegates.$each.$response.$errors[index-1].age" :key="error">
                                  <div class="error-msg">{{ error.$message }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>

              </ul>
              <dl class="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">

                <!-- <ul role="list" class="divide-y divide-gray-200">
                  <li v-if="viewState.childTicket" class="flex px-4 py-6 sm:px-6">
                    <div>
                      <p v-if="viewState.standardFree > 0" class="text-green-500 text-sm font-medium">With {{ viewState.childTicketQuantity }} {{ viewState.childTicket.title }} ticket/s your first {{ viewState.standardFree }} {{ viewState.standardTicket.title }} ticket/s will be free</p>
                      <p v-if="viewState.ratioRequired > 0" class="text-red-500 text-sm font-medium">With {{ viewState.childTicketQuantity }} {{ viewState.childTicket.title }} ticket/s you are required to have a minimum of {{ viewState.ratioRequired }} {{ viewState.standardTicket.title }} ticket/s for supervision</p>
                      <p class="italic text-sm font-medium">For group bookings of more than 15 children please contact us directly via email kids@prayerstorm.org</p>
                    </div>
                  </li>
                </ul> -->

                <!-- <div class="flex items-center justify-between">
                  <dt class="text-sm">Donation Total</dt>
                  <dd class="text-sm font-medium text-gray-900">£ 10.00</dd>
                </div> -->
                <div v-for="totalLine in viewState.totalLines" :key="totalLine.lineId"  class="flex items-center justify-between">
                  <dt class="text-sm">{{ totalLine.title }} Tickets ({{ totalLine.quantity }} x £{{ stripeUnitAmountString('gbp', totalLine.price) }})</dt>
                  <dd class="text-sm font-medium text-gray-900">£ {{ stripeUnitAmountString('gbp', totalLine.price * totalLine.quantity) }}</dd>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt class="text-base font-bold">Order Total</dt>
                  <dd class="text-base font-medium text-gray-900">£ {{ stripeUnitAmountString('gbp', viewState.totalOrder) }}</dd>
                </div>
              </dl>

              <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                <ErrorMessage :formError="viewState.error" class="mb-6"></ErrorMessage>
                <div class="relative flex gap-x-3 pb-4">
                  <div class="flex h-6 items-center">
                    <input v-model="viewState.orderDetails.agreements" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                  </div>
                  <div class="text-sm leading-6">
                    <p class="text-gray-900">I am aware of the <a href="https://docs.prayerstorm.org/privacy/privacy-policy" target="_blank" class="text-blue font-bold">privacy policy</a> and agree to the <a href="https://docs.prayerstorm.org/reference/events/tickets/cancellations-and-refunds" target="_blank" class="text-blue font-bold">cancellations and refunds policy</a>.</p>
                  </div>
                </div>
                <button v-if="viewState.totalOrder > 0" @click.prevent="submitForm()" class="w-full rounded-md border border-transparent bg-blue px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 focus:ring-offset-gray-50">
                  Make payment £{{ stripeUnitAmountString('gbp', viewState.totalOrder) }}
                </button>
                <button v-else @click.prevent="submitForm()" class="w-full rounded-md border border-transparent bg-blue px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 focus:ring-offset-gray-50">
                  Order Tickets
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="bg-gray-100">
    <div class="mx-auto py-12 lg:py-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div v-if="viewState.standardTicket" class="">

        <h2 class="text-xl font-medium text-gray-900 mt-8">Attendance ({{ attendanceSelectionCount }} of {{ viewState.attendanceOptions.length }} Sessions)</h2>
        <p v-if="viewState.sessionAttendance" class="mt-2">Please select all the sessions that your order will be attending. This helps us to ensure as many people can attend as possible. <span class="font-bold italic">The ability to update your attendance may not be available closer to the event.</span> </p>
        <p v-else class="mt-2">This event is not setup to allow selection of individual session attendance. <span class="font-bold italic">This event works best with full attendance.</span> </p>

        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-6">
          <div @click.prevent="toggleAttendance(option)" v-for="option in viewState.attendanceOptions" :key="option.id" :class="[option.attend === true ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600', 'relative flex items-center space-x-3 rounded-lg border border-gray-300 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-white cursor-pointer']">
            <div class="flex-shrink-0">
              <CheckIcon v-if="option.attend === true" class="h-6 w-6 text-white inline-block mr-2"></CheckIcon>
              <XMarkIcon v-else class="h-6 w-6 text-white inline-block mr-2"></XMarkIcon>
            </div>
            <div class="min-w-0 flex-1">
              <div href="#" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-bold text-white">
                  {{ option.title }}
                  <span v-if="option.attend === true" class="truncate text-sm text-white">(Attending)</span>
                  <span v-else class="truncate text-sm text-white">(Not Attending)</span>
                </p>
                <p class="truncate text-sm text-white">{{ $filters.dateTimeMed(option.publishedAt) }}</p>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-xl font-medium text-gray-900 mt-8">Order Details</h2>
        <div class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
          <div class="border-t border-gray-200 px-4 py-6 sm:px-6">

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 py-2">
              <label class="block text-sm font-medium text-gray-900 sm:pt-1.5"></label>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <button @click.prevent="copyFromDelegate()" class="text-sm font-medium text-blue">Copy from first delegate</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <TransitionRoot as="template" :show="ticketsModal.open">
    <Dialog as="div" class="relative z-10" @close="ticketsModal.open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-gray-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="text-left">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Select ticket to add</DialogTitle>
                    <div>
                      <dl class="mt-5">
                        <div v-for="item in viewState.tickets" :key="item.id" class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt class="truncate text-md font-medium text-gray-500">{{ item.title }}</dt>
                          <dd class="mt-1 text-sm font-semibold tracking-tight text-gray-900">{{ item.description }} Price:{{ item.price }} Child:{{ item.child }} Max:{{ item.max }}</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" @click="ticketsModal.open = false" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { mapGetters, useStore } from 'vuex'
import { DateTime } from 'luxon'
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { helpers, email } from '@vuelidate/validators'
// import {
//   XMarkIcon,
//   CheckIcon
// } from '@heroicons/vue/24/outline'
import { Dialog, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useCurrencies } from '@/composables/useCurrencies'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { required$, integer$, fieldMinValue$, fieldMaxValue$, fieldMaxLength$ } from '@/lib/validators'
import responseHelper from '@/helpers/response.helper'

export default defineComponent({
  components: {
    // XMarkIcon,
    // CheckIcon,
    ErrorMessage,
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot
    // RadioGroup,
    // RadioGroupOption
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const { stripeUnitAmountString } = useCurrencies()

    const ticketsModal = reactive({
      open: false
    })

    const CLONE_DELEGATE = {
      firstName: '',
      lastName: '',
      email: ''
    }

    const CLONE_CHILD_DELEGATE = {
      firstName: '',
      lastName: '',
      age: '',
      gender: ''
    }

    const viewState = reactive({
      loading: true,
      loadError: undefined,
      error: responseHelper.noError,
      event: undefined,
      standardTicket: undefined,
      standardTicketQuantity: 1,
      childTicket: undefined,
      childTicketQuantity: 0,
      sessionAttendance: true,
      ratioRequired: 0,
      standardFree: 0,
      ratios: undefined,
      free: undefined,
      totalLines: [],
      totalOrder: 0,
      delegates: [],
      childDelegates: [],
      tickets: [],
      orderDetails: {
        email: '',
        firstName: '',
        lastName: '',
        tel: '',
        country: '',
        townCity: '',
        heardFrom: '',
        isPartner: '',
        agreements: false
      },
      attendanceOptions: []
    })

    const rules = {
      delegates: {
        $each: helpers.forEach({
          firstName: { required$, maxLength: fieldMaxLength$(30) },
          lastName: { required$, maxLength: fieldMaxLength$(30) },
          email: { required$, email }
        })
      },
      childDelegates: {
        $each: helpers.forEach({
          firstName: { required$, maxLength: fieldMaxLength$(30) },
          lastName: { required$, maxLength: fieldMaxLength$(30) },
          age: { required$, integer$, minValue: fieldMinValue$(0), maxValue: fieldMaxValue$(17) },
          gender: { required$ }
        })
      },
      orderDetails: {
        email: { required$, email, $autoDirty: true },
        firstName: { required$, maxLength: fieldMaxLength$(30), $autoDirty: true },
        lastName: { required$, maxLength: fieldMaxLength$(30), $autoDirty: true },
        tel: { required$, maxLength: fieldMaxLength$(20), $autoDirty: true },
        country: { required$, $autoDirty: true },
        townCity: { required$, maxLength: fieldMaxLength$(30), $autoDirty: true },
        heardFrom: { required$, $autoDirty: true },
        isPartner: { required$, $autoDirty: true }
      }
    }

    const v$ = useVuelidate(rules, viewState)

    const attendanceSelectionCount = computed(() => {
      var attendCount = 0
      if (viewState.attendanceOptions.length > 0) {
        for (var i = 0; i < viewState.attendanceOptions.length; i++) {
          if ('attend' in viewState.attendanceOptions[i] && viewState.attendanceOptions[i].attend === true) {
            attendCount++
          }
        }
      }
      return attendCount
    })

    const toggleAttendance = (option) => {
      if (!viewState.sessionAttendance) {
        return
      }

      if ('attend' in option) {
        option.attend = !option.attend
      } else {
        option.attend = true
      }
    }

    const generateDelegates = () => {
      if (viewState.delegates.length < viewState.standardTicketQuantity) {
        for (let i = viewState.delegates.length; i < viewState.standardTicketQuantity; i++) {
          viewState.delegates.push({ ...CLONE_DELEGATE })
        }
      } else if (viewState.delegates.length > viewState.standardTicketQuantity) {
        viewState.delegates = viewState.delegates.slice(0, viewState.standardTicketQuantity)
      }
      if (viewState.childDelegates.length < viewState.childTicketQuantity) {
        for (let i = viewState.childDelegates.length; i < viewState.childTicketQuantity; i++) {
          viewState.childDelegates.push({ ...CLONE_CHILD_DELEGATE })
        }
      } else if (viewState.childDelegates.length > viewState.childTicketQuantity) {
        viewState.childDelegates = viewState.childDelegates.slice(0, viewState.childTicketQuantity)
      }
    }

    onMounted(async () => {
      try {
        const result = await store.dispatch('checkout/getOptionTickets', {
          optionId: route.params.optionId
        })

        viewState.event = result.event
        if (viewState.event && viewState.event.imageUrl) {
          viewState.event.imageUrl = process.env.VUE_APP_BLOB_CONTAINER + '/images/' + viewState.event.imageUrl
        }
        viewState.sessionAttendance = result.sessionAttendance
        viewState.standardTicket = result.standardTicket
        viewState.childTicket = result.childTicket
        viewState.tickets = result.tickets
        if (viewState.childTicket) {
          viewState.childTicketQuantity = 1
        }
        viewState.ratios = result.ratios
        viewState.free = result.free
        viewState.attendanceOptions = result.attendanceOptions
        for (let index = 0; index < viewState.attendanceOptions.length; index++) {
          if (viewState.sessionAttendance) {
            viewState.attendanceOptions[index].attend = false
          } else {
            viewState.attendanceOptions[index].attend = true
          }
        }
        recalculate()

        viewState.loading = false
      } catch (e) {
        viewState.ticketInfo = undefined
        viewState.loadError = e

        store.commit('global/setErrorModal', {
          title: 'Error Loading',
          description: 'Please refresh the page and try again. If the problem persists, please contact support.',
          open: true
        })
      }
    })

    const copyFromDelegate = () => {
      viewState.orderDetails.firstName = viewState.delegates[0].firstName
      viewState.orderDetails.lastName = viewState.delegates[0].lastName
      viewState.orderDetails.email = viewState.delegates[0].email
    }

    const increaseStandard = () => {
      console.log('increaseStandard')
      if (viewState.standardTicket && viewState.standardTicketQuantity < viewState.standardTicket.max) {
        viewState.standardTicketQuantity++
      }
      recalculate()
    }
    const decreaseStandard = () => {
      if (viewState.standardTicketQuantity > 1) {
        viewState.standardTicketQuantity--
      }
      recalculate()
    }
    const increaseChild = () => {
      if (viewState.childTicket && viewState.childTicketQuantity < viewState.childTicket.max) {
        viewState.childTicketQuantity++
      }
      recalculate()
    }
    const decreaseChild = (ticket) => {
      if (viewState.childTicketQuantity > 1) {
        viewState.childTicketQuantity--
      }
      recalculate()
    }

    const recalculate = () => {
      calculateRatioRequired()
      calculateStandardFree()
      calculateTotals()
      generateDelegates()
    }

    const calculateRatioRequired = () => {
      let req = 0
      if (viewState.ratios && viewState.childTicket) {
        // Iterate through tiers to find the highest tier the customer qualifies for
        for (let i = viewState.ratios.length - 1; i >= 0; i--) {
          if (viewState.childTicketQuantity >= viewState.ratios[i].qty) {
            req = viewState.ratios[i].req
            break // Exit loop once the highest tier is found
          }
        }
      }
      if (req > 0) {
        viewState.ratioRequired = req
        if (viewState.standardTicketQuantity < req) {
          viewState.standardTicketQuantity = req
        }
      }
      return req
    }

    const calculateStandardFree = () => {
      let free = 0
      if (viewState.free && viewState.childTicket) {
        // Iterate through tiers to find the highest tier the customer qualifies for
        for (let i = viewState.free.length - 1; i >= 0; i--) {
          if (viewState.childTicketQuantity >= viewState.free[i].qty) {
            free = viewState.free[i].free
            break // Exit loop once the highest tier is found
          }
        }
      }
      if (free > 0) {
        viewState.standardFree = free
      }
      return free
    }

    const calculateTotals = () => {
      const totals = []
      if (viewState.standardFree > 0) {
        totals.push({
          lineId: 'adult-free',
          title: viewState.standardTicket.title,
          quantity: viewState.standardFree > viewState.standardTicketQuantity ? viewState.standardTicketQuantity : viewState.standardFree,
          price: 0
        })
      }
      if (viewState.standardTicketQuantity - viewState.standardFree > 0) {
        totals.push({
          lineId: 'adult',
          title: viewState.standardTicket.title,
          quantity: viewState.standardTicketQuantity - viewState.standardFree,
          price: viewState.standardTicket.price
        })
      }
      if (viewState.childTicket) {
        totals.push({
          lineId: 'child',
          title: viewState.childTicket.title,
          quantity: viewState.childTicketQuantity,
          price: viewState.childTicket.price
        })
      }
      viewState.totalLines = totals
      let totalOrder = 0
      for (let i = 0; i < totals.length; i++) {
        totalOrder += totals[i].price * totals[i].quantity
      }
      viewState.totalOrder = totalOrder
    }

    const submitForm = async () => {
      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        viewState.error = responseHelper.createError('Form Validation', ['Please fix all the validation errors on the form before submitting'])
        return
      }

      var attendValid = false
      if (viewState.sessionAttendance) {
        if (viewState.attendanceOptions) {
          for (let index = 0; index < viewState.attendanceOptions.length; index++) {
            if (viewState.attendanceOptions[index].attend) {
              attendValid = true
            }
          }
        }
      } else {
        attendValid = true
      }

      if (!attendValid) {
        viewState.error = responseHelper.createError('Session Attendance Required', ['Please select the sessions that you would like to attend'])
        return
      }

      if (!viewState.orderDetails.agreements) {
        viewState.error = responseHelper.createError('Privacy & Terms', ['Please acknowledge the privacy policy and agree to the cancellations and refunds policy.'])
        return
      }

      viewState.error = responseHelper.noError

      const order = {
        optionId: route.params.optionId,
        standardTicketQuantity: viewState.standardTicketQuantity,
        childTicketQuantity: viewState.childTicketQuantity,
        orderDetails: viewState.orderDetails,
        delegates: viewState.delegates,
        childDelegates: viewState.childDelegates,
        attendanceOptions: viewState.attendanceOptions
      }

      try {
        // show the loading modal
        store.commit('global/setLoadingModal', {
          open: true
        })

        const response = await axios.post('checkout/attend-conf', order)
        window.location.replace(response.data.result.sessionUrl)
      } catch (error) {
        viewState.error = responseHelper.getMessage(error)
      } finally {
        store.commit('global/setLoadingModal', {
          open: false
        })
      }
    }

    return {
      v$,
      viewState,
      DateTime,
      increaseStandard,
      decreaseStandard,
      increaseChild,
      decreaseChild,
      stripeUnitAmountString,
      copyFromDelegate,
      toggleAttendance,
      attendanceSelectionCount,
      submitForm,
      ticketsModal
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
