<template>
  <section id="faqs-panel" class="py-10">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h2 class="font-display text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
        Frequently Asked Questions
      </h2>
      <dl class="mt-4 space-y-6 divide-y divide-gray-900/10">
        <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold">{{ faq.question }}</span>
              <span class="ml-6 flex items-center">
                <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
              </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-base leading-7 text-gray-600" v-html="faq.answer"></p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </div>
  </section>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: 'How can I financially support this event?',
    answer: 'Prayer Storm is a non profit organisation and aims to make tickets free or as affordable as possible. If you would like to support this event and help make it possible via financial support, you can do so here: <a href="/support/4/prayer-storm-conference-2025" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Prayer Storm Conference 2025 Support</a>.'
  },
  {
    question: 'Where can I order tickets?',
    answer: 'You can order tickets via this page above. Please find the button \'Get your tickets\'. PLEASE NOTE: Only people with a valid QR code tickets and a delegate name upon the ticket will be permitted entrance to attend. This includes children that want to attend the Prayer Storm Conference 2025 Main Venue and the Prayer Storm Kids Conference 2025.'
  },
  {
    question: 'Where and when is this taking place?',
    answer: 'Manchester Central, Petersfield, M2 3GX, UK. Taking place on 21-23 August 2025.'
  },
  {
    question: 'Can I bring my children?',
    answer: 'Due to the size and security of Manchester Central, all people attending the Prayer Storm Conference 2025 will require a named ticket to gain entrance into both the Main Venue and the Kids Venue. Children and Youth 7-16 years of age will be able to attend the Prayer Storm Kids Venue taking place at the same the time as the Main Venue. PLEASE NOTE: that the Kids Conference is only for 7-16 year olds due to safeguarding and event safety. Children younger/older that are required to attend with you, will have to attend the main venue and will require you to purchase a full Main Conference ticket for them, so they can access the Main Venue with you. They will remain your responsibilty at all times in the Main Venue.'
  },
  {
    html: true,
    question: 'What hotels are nearby?',
    answer: 'Marketing Manchester Convention Bureau is the official accommodation provider for Prayer Storm 2025 Annual Conference. Please see the section titled \'Accomodation in Manchester\' above.'
  },
  // {
  //   question: 'When is this taking place?',
  //   answer: 'The conference will take place between Tuesday 25th and Thursday 27th June, 2024. Please see the schedule for more details'
  // },
  // {
  //   question: 'What is the time/duration of each session?',
  //   answer: 'Each session is estimated to 2.5 - 3 hours. Morning sessions 10am-12.30pm, afternoon sessions 2.30-5pm and evening sessions 7-10pm. All UK timezone (BST). End times may vary. PLEASE NOTE: If you are travelling from abroad, your web browser may show you the dates in your local time zone. Please ensure you attend for the UK times.'
  // },
  // {
  //   question: 'What is the schedule and format?',
  //   answer: 'There are two ways to take part in this event. Firstly, this conference is available to attend in-person by purchasing a "General Admission" conference ticket. Secondly, it will also be livestreamed so that you can watch it online via our YouTube channel - PrayerStormTV.'
  // },
  // {
  //   question: 'Can I get a refund?',
  //   answer: 'Please refer to our <a href="https://docs.prayerstorm.org/reference/events/tickets/cancellations-and-refunds" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Cancellations and Refunds Policy</a>.'
  // },
  // {
  //   question: 'Can\'t afford the ticket price?',
  //   answer: 'Prayer Storm is a non profit organisation. We do not charge ticket prices to generate income and we aim to keep ticket costs as low as possible to enable as many people to attend our events. Many of our events and live streams are free, but we do need to try and recover some of the costs involved in running our larger conferences and events. We do realise though, that the full ticket cost may still be an issue for some. If this is the case, then please email us at info@prayerstorm.org'
  // },
  {
    question: 'Can you provide a letter for my visa?',
    answer: 'Unfortunately we are unable to provide letters or documentation for visa applications.'
  },
  {
    question: 'Can you provide information for my travel arrangements?',
    answer: 'We cannot provide travel arrangements for individuals attending.'
  },
  {
    question: 'Can I get a refund?',
    answer: 'Please refer to our <a href="https://docs.prayerstorm.org/reference/events/tickets/cancellations-and-refunds" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Cancellations and Refunds Policy</a>.'
  }
]
</script>
