<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6" id="volunteer-form" @submit.prevent="volunteer">
          <h2 class="font-bold text-blue">Volunteer Interest Form</h2>
          <div class="block text-sm text-gray-700">Everyone has skills to offer, you may just not realise it. Some of the things you can offer are your enthusiasm, a regular commitment of your time and your desire to get involved and make a difference. Please use the form below to tell us more about yourself and express interest in volunteering at Prayer Storm.</div>
          <p class="text-red-500 font-bold">Please attempt to complete all fields requested below.</p>
          <div>
              <label for="interests" class="block text-sm font-medium text-gray-700">
                Which specific area of the Prayer Storm August Conference 2025 would you like to serve in? Please note that you can select more than one option. The areas available are: Event Coordinator, Registration and Ticketing, Childcare & Youth Services, Ushering & Guest Services, Hospitality for Volunteers & VIPs, Technical & AV Production, Safety & Security, Marketing & Social Media, and Health & First Aid Services. Please indicate your preference in the box below.
              </label>
              <textarea v-model="formState.volunteerForm.interests" id="interests" name="interests" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
          </div>
          <div>
                <label class="block text-sm font-medium text-gray-700" for="title">Title</label>
                <ul id="title">
                    <li class="choice-1 depth-1 mt-1 mr-1">
                        <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" value="Mr" v-model="formState.volunteerForm.title">
                        <label class="text-sm font-medium text-gray-700 pl-2" for="title">Mr</label>
                    </li>
                    <li class="choice-2 depth-1 mt-1">
                        <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" value="Mrs" v-model="formState.volunteerForm.title" >
                        <label class="text-sm font-medium text-gray-700 pl-2" for="title">Mrs</label>
                    </li>
                    <li class="choice-3 depth-1 mt-1">
                        <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" value="Miss" v-model="formState.volunteerForm.title">
                        <label class="text-sm font-medium text-gray-700 pl-2" for="title">Miss</label>
                    </li>
                    <li class="choice-4 depth-1 mt-1">
                        <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" value="Ms" v-model="formState.volunteerForm.title">
                        <label class="text-sm font-medium text-gray-700 pl-2" for="title">Ms</label>&nbsp;
                    </li>
                    <li class="choice-5 depth-1 mt-1">
                        <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"  value="Other" v-model="formState.volunteerForm.title">
                        <label class="text-sm font-medium text-gray-700 pl-2" for="title">Other</label>
                    </li>
                    <div  v-show="formState.volunteerForm.title === 'Other'">
                        <label class="text-sm font-medium text-gray-700 pr-2" for="title"> (Such as Dr.) *</label>
                        <input type="text" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-model="formState.volunteerForm.otherTitle">
                    </div>
                </ul>
            </div>
            <div class="grid grid-cols-4 gap-6">
                <div class="col-span-4 sm:col-span-2">
                    <label for="firstName" class="block text-sm font-medium text-gray-700">First name</label>
                    <input  v-model="formState.volunteerForm.firstName" type="text" name="firstName" id="firstName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="lastName" class="block text-sm font-medium text-gray-700">Last name</label>
                    <input v-model="formState.volunteerForm.lastName" type="text" name="lastName" id="lastName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="dateOfBirth" class="block text-sm font-medium text-gray-700">Date of Birth</label>
                    <input  v-model="formState.volunteerForm.dateOfBirth" type="date" name="dateOfBirth" id="dateOfBirth" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="email" class="block text-sm font-medium text-gray-700">Email Address</label>
                    <input v-model="formState.volunteerForm.email" type="email" name="email" id="email" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                    <div class="col-span-4 sm:col-span-2">
                    <label for="mobile" class="block text-sm font-medium text-gray-700">Mobile Number</label>
                    <input  v-model="formState.volunteerForm.mobile" type="tel" name="mobile" id="mobile" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>
            </div>
            <div class="grid grid-cols-4 gap-6">
                <div class="col-span-4 sm:col-span-2">
                    <label for="addressOne" class="block text-sm font-medium text-gray-700">Address Line 1</label>
                    <input  v-model="formState.volunteerForm.addressOne" type="text" name="mobile" id="addressOne" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="addressTwo" class="block text-sm font-medium text-gray-700">Address Line 2</label>
                    <input  v-model="formState.volunteerForm.addressTwo" type="text" name="addressTwo" id="addressTwo" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="city" class="block text-sm font-medium text-gray-700">Town/City</label>
                    <input  v-model="formState.volunteerForm.city" type="text" name="city" id="city" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="county" class="block text-sm font-medium text-gray-700">County</label>
                    <input  v-model="formState.volunteerForm.county" type="text" name="county" id="county" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="postCode" class="block text-sm font-medium text-gray-700">Postal Code</label>
                    <input  v-model="formState.volunteerForm.postCode" type="text" name="postCode" id="postCode" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                    <select v-model="formState.volunteerForm.country" id="country" name="country" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm">
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia (Plurinational State of)</option>
                        <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">British Indian Ocean Territory</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">Congo (Democratic Republic of the)</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (Kingdom of)</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">Heard Island and McDonald Islands</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran (Islamic Republic of)</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland (Republic of)</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (Democratic People's Republic of)</option>
                        <option value="KR">Korea (Republic of)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Lao People's Democratic Republic</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia (Federated States of)</option>
                        <option value="MD">Moldova (Republic of)</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MK">North Macedonia (Republic of)</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">Palestine (State of)</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="RE">Réunion</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option><option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten (Dutch part)</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania (United Republic of)</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
                        <option value="UM">United States Minor Outlying Islands</option>
                        <option value="US">United States of America</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City State</option>
                        <option value="VE">Venezuela (Bolivarian Republic of)</option>
                        <option value="VN">Vietnam</option>
                        <option value="VG">Virgin Islands (British)</option>
                        <option value="VI">Virgin Islands (U.S.)</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                        <option value="AX">Åland Islands</option>
                    </select>
                </div>
            </div>
            <div>
                <label for="heard" class="block text-sm font-medium text-gray-700">How did you find out about volunteering with Prayer Storm?</label>
                <textarea  v-model="formState.volunteerForm.heard" id="heard" name="heard" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>
            <div>
                <label class="text-sm font-medium text-gray-700" for="volunteeredBefore">Have you done any volunteering work before? <span>*</span></label>
                    <ul id="volunteeredBefore">
                        <li class="choice-1 depth-1 wpforms-selected mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="true" v-model="formState.volunteerForm.volunteeredBefore">
                            <label class="text-sm font-medium text-gray-700 pl-2"  for="volunteeredBefore">Yes</label>
                        </li>
                        <li class="choice-2 depth-1 mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="false"  v-model="formState.volunteerForm.volunteeredBefore">
                            <label class="text-sm font-medium text-gray-700 pl-2" for="volunteeredBefore">No</label>
                        </li>
                    </ul>
            </div>
            <div   v-show="formState.volunteerForm.volunteeredBefore === true">
                <label for="volunteering" class="block text-sm font-medium text-gray-700">If your answer is yes, please give details of your past volunteering work:</label>
                <textarea  v-model="formState.volunteerForm.volunteering" id="volunteering" name="volunteering" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>

            <div>
                <label for="why" class="block text-sm font-medium text-gray-700">Why do you want to volunteer with Prayer Storm?</label>
                <textarea  v-model="formState.volunteerForm.why" id="why" name="why" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>

            <div>
                <label for="skills" class="block text-sm font-medium text-gray-700">What skills do you have that you would like to use as a volunteer?</label>
                <textarea  v-model="formState.volunteerForm.skills" id="skills" name="skills" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>

            <div>
                <label for="learn" class="block text-sm font-medium text-gray-700">What area would you like to learn and grow in?</label>
                <textarea  v-model="formState.volunteerForm.learn" id="learn" name="learn" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>

            <div>
                <label for="availability" class="block text-sm font-medium text-gray-700">What days or hours are you available to volunteer every week/month?</label>
                <textarea  v-model="formState.volunteerForm.availability" id="availability" name="availability" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>
            <div>
                    <label class="text-sm font-medium text-gray-700" for="computers">Are you willing to be trained on basic computer systems? <span>*</span></label>
                        <ul id="computers">
                            <li class="depth-1 mt-1">
                                <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="true" v-model="formState.volunteerForm.computers">
                                <label class="text-sm font-medium text-gray-700 pl-2" for="computers">Yes</label>
                            </li>
                            <li class="depth-1 mt-1">
                                <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"  v-bind:value="false" v-model="formState.volunteerForm.computers">
                                <label class="text-sm font-medium text-gray-700 pl-2" for="computers">No</label>
                            </li>
                        </ul>
                </div>
                <div>
                    <label class="text-sm font-medium text-gray-700" for="car">Do you have a car that you could use when you are volunteering? <span>*</span></label>
                        <ul id="car" class="mt-1">
                            <li class="depth-1 mt-1">
                                <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="true" v-model="formState.volunteerForm.car" >
                                <label class="text-sm font-medium text-gray-700 pl-2" for="car">Yes</label>
                            </li>
                            <li class="depth-1 mt-1">
                                <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="false" v-model="formState.volunteerForm.car">
                                <label class="text-sm font-medium text-gray-700 pl-2" for="car">No</label>
                            </li>
                        </ul>
                </div>
                <div>
                    <label class="text-sm font-medium text-gray-700" for="healthConditions">Do you have any health conditions that you would like to make usaware of that may affect you from some types of volunteering? <span class="wpforms-required-label">*</span></label>
                        <ul id="healthConditions">
                            <li class="depth-1 mt-1">
                                <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="true" v-model="formState.volunteerForm.healthConditions">
                                <label class="text-sm font-medium text-gray-700 pl-2" for="healthConditions">Yes</label>
                            </li>
                            <li class="depth-1 mt-1">
                                <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="false" v-model="formState.volunteerForm.healthConditions">
                                <label class="text-sm font-medium text-gray-700 pl-2" for="healthConditions">No</label>
                            </li>
                        </ul>
                </div>
                <div v-show="formState.volunteerForm.healthConditions === true">
                <label for="healthInformation" class="block text-sm font-medium text-gray-700">If Yes please tell us more below:</label>
                <textarea  v-model="formState.volunteerForm.healthInformation" id="healthInformation" name="healthInformation" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>

            <div>
                <label for="experience" class="block text-sm font-medium text-gray-700">What experience do you have in the area you want to volunteer in?</label>
                <textarea  v-model="formState.volunteerForm.experience" id="experience" name="experience" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>

            <div class="block text-sm font-medium text-gray-700">
              Prayer Storm is a community of Bible believing followers of Jesus Christ with a mandate to live a lifestyle of Holiness, Prayer and fasting. Our passion is to awaken a generation to this lifestyle of Prayer and fasting and preparing the way of the Lord and we do so through solemn assembly type gatherings twice a year, conferences, prayer meetings, teaching, equipping the body of Christ in the area of prayer and worship.
            </div>
            <div class="block text-sm font-medium text-gray-700">
              Through Prayer Storm Live we aim to raise up individuals and teams that deliver quality standard broadcasts. We want each volunteer not to just be talented but feel called to serve, are anointed to do so, and are equipped and living a lifestyle that carries an awakening sound in all that they do because of the lifestyle they already live, whether serving at Prayer Storm, in their place of education, local Church or workplace, we believe that authenticity when serving is essential and expect this from every team member no matter how they serve: this means living an accountable lifestyle to local church leaders, living Holy and Set apart unto the Lord.
            </div>
            <div class="block text-sm font-medium text-gray-700">
              We ask for those who are volunteering with the livestreams to commit to be part of our streams not just in a voluntary capacity but also by coming to worship along with the lives either through viewing online or joining the worship and prayer congregation when possible. We are a community that aims to raise up constant prayer and worship and will have more times of worship, prayer and fasting together off camera and expect those that serve especially in front of the cameras will make every effort to be part of these times of worship too.
            </div>
            <h2 class="font-bold text-blue">References</h2>
            <div class="block text-sm font-medium text-gray-700">
              Please provide names of two references one of which should be a leader at your local Church who has known you for 2 years or more.
            </div>

            <h2 class="font-bold text-blue">First Reference (Church Leader)</h2>
            <div class="grid grid-cols-4 gap-6">
                <div class="col-span-4 sm:col-span-2">
                    <label for="refOneFirstName" class="block text-sm font-medium text-gray-700">First name</label>
                    <input  v-model="formState.volunteerForm.refOneFirstName" type="text" name="refOneFirstName" id="refOneFirstName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="refOneLastName" class="block text-sm font-medium text-gray-700">Last name</label>
                    <input v-model="formState.volunteerForm.refOneLastName" type="text" name="refOneLastName" id="refOneLastName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="refOneRelationship" class="block text-sm font-medium text-gray-700">What is your relationship with this person?</label>
                    <input  v-model="formState.volunteerForm.refOneRelationship" type="text" name="refOneRelationship" id="refOneRelationship" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="refOneEmail" class="block text-sm font-medium text-gray-700">Email</label>
                    <input v-model="formState.volunteerForm.refOneEmail" type="email" name="refOneEmail" id="refOneEmail" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                    <div class="col-span-4 sm:col-span-2">
                    <label for="refOneNumber" class="block text-sm font-medium text-gray-700">Contact Number</label>
                    <input  v-model="formState.volunteerForm.refOneNumber" type="tel" name="refOneNumber" id="refOneNumber" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>
            </div>

            <h2 class="font-bold text-blue">Second Reference</h2>
            <div class="grid grid-cols-4 gap-6">
                <div class="col-span-4 sm:col-span-2">
                    <label for="refTwoFirstName" class="block text-sm font-medium text-gray-700">First name</label>
                    <input  v-model="formState.volunteerForm.refTwoFirstName" type="text" name="refTwoFirstName" id="refTwoFirstName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="refTwoLastName" class="block text-sm font-medium text-gray-700">Last name</label>
                    <input v-model="formState.volunteerForm.refTwoLastName" type="text" name="refTwoLastName" id="refTwoLastName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="refTwoRelationship" class="block text-sm font-medium text-gray-700">What is your relationship with this person?</label>
                    <input  v-model="formState.volunteerForm.refTwoRelationship" type="text" name="refTwoRelationship" id="refTwoRelationship" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="refTwoEmail" class="block text-sm font-medium text-gray-700">Email</label>
                    <input v-model="formState.volunteerForm.refTwoEmail" type="email" name="refTwoEmail" id="refTwoEmail" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                    <div class="col-span-4 sm:col-span-2">
                    <label for="refTwoNumber" class="block text-sm font-medium text-gray-700">Contact Number</label>
                    <input  v-model="formState.volunteerForm.refTwoNumber" type="tel" name="refTwoNumber" id="refTwoNumber" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>
            </div>
            <div class="font-bold text-blue">Declaration</div>
            <div class="block text-sm font-medium text-gray-700">Prayer Storm have to ensure that all volunteers are safe to work with young people and a Disclosure will be requested if required. We will only take into account a criminal record if it could be important to the volunteering you want to do.
            </div>
            <div>
                <label class="text-sm font-medium text-gray-700">Are you willing to undergo a Disclosure if required? <span>*</span></label>
                    <ul id="disclosure">
                        <li class="depth-1 mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="true" v-model="formState.volunteerForm.disclosure" >
                            <label class="text-sm font-medium text-gray-700 pl-2" for="disclosure">Yes</label>
                        </li>
                        <li class="depth-1 mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"  v-bind:value="false" v-model="formState.volunteerForm.disclosure">
                            <label class="text-sm font-medium text-gray-700 pl-2" for="disclosure">No</label>
                        </li>
                    </ul>
            </div>
            <div>
                <label class="text-sm font-medium text-gray-700">Has there ever been any cause for concern regarding your conduct with children? <span>*</span></label>
                <ul id="concern">
                    <li class="depth-1 mt-1">
                        <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="true" v-model="formState.volunteerForm.concern">
                        <label class="text-sm font-medium text-gray-700 pl-2" for="concern">Yes</label>
                    </li>
                    <li class="depth-1 mt-1">
                        <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="false" v-model="formState.volunteerForm.concern">
                        <label class="text-sm font-medium text-gray-700 pl-2" for="concern">No</label>
                    </li>
                </ul>
            </div>
            <div>
                <label class="text-sm font-medium text-gray-700">To your knowledge have you ever had any allegation made against you which has been reported to, and investigated by Social Services  and / or the Police? If yes please give details on a separate sheet
                    <span>*</span>
                </label>
                    <ul id="allegation">
                        <li class="depth-1 mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="true"  v-model="formState.volunteerForm.allegation">
                            <label class="text-sm font-medium text-gray-700 pl-2" for="allegation">Yes</label>
                        </li>
                        <li class="choice-2 depth-1 mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" v-bind:value="false"  v-model="formState.volunteerForm.allegation">
                            <label class="text-sm font-medium text-gray-700 pl-2" for="allegation">No</label>
                        </li>
                    </ul>
            </div>
            <div>
                <label class="text-sm font-medium text-gray-700">Are you eligible to work in the UK?</label>
                    <ul id="eligible">
                        <li class="depth-1 mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"  v-bind:value="true" v-model="formState.volunteerForm.eligible">
                            <label class="text-sm font-medium text-gray-700 pl-2" for="eligible">Yes</label>
                        </li>
                        <li class="depth-1 mt-1">
                            <input type="radio" class="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100"  v-bind:value="false" v-model="formState.volunteerForm.eligible">
                            <label class="text-sm font-medium text-gray-700 pl-2" for="eligible">No</label>
                        </li>
                    </ul>
            </div>
            <div class="block text-sm font-medium text-gray-700">I hereby authorise Prayer Storm to obtain references to support this application and accept and release Prayer Storm and referees from liability caused by giving and receiving information. I give express permission for the personal data on this form to be held and processed by Prayer Storm. I confirm that the information given on this form is correct and any misleading or falsification of information may be proper cause for rejection.
            </div>
            <div class="grid grid-cols-4 gap-6">
                <div class="col-span-4 sm:col-span-2">
                    <label for="fullName" class="block text-sm font-medium text-gray-700">Full Name</label>
                    <input v-model="formState.volunteerForm.fullName" type="text" name="fullName" id="fullName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>

                <div class="col-span-4 sm:col-span-2">
                    <label for="dateCompleted" class="block text-sm font-medium text-gray-700">Date / Time</label>
                    <input v-model="formState.volunteerForm.dateCompleted" type="datetime-local" name="dateCompleted" id="dateCompleted" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
                </div>
            </div>

        <ErrorMessage :formError="formState.formError" class="mt-6"></ErrorMessage>
        <SuccessMessage :successMessage="formState.successMessage" class="mt-6"></SuccessMessage>
        <div>
          <button type="submit" :disabled="formState.formSubmitted" class="disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
            Volunteer
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { defineComponent, reactive } from 'vue'
import responseHelper from '@/helpers/response.helper'
import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default defineComponent({
  name: 'VolunteerForm',
  components: {
    SuccessMessage,
    ErrorMessage
  },
  setup () {
    const formState = reactive({
      volunteerForm: {
        otherTitle: '',
        interests: '',
        title: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        addressOne: '',
        addressTwo: '',
        city: '',
        county: '',
        postCode: '',
        country: '',
        email: '',
        mobile: '',
        heard: '',
        volunteeredBefore: '',
        volunteering: '',
        why: '',
        skills: '',
        learn: '',
        availability: '',
        computers: '',
        car: '',
        healthConditions: '',
        healthInformation: '',
        experience: '',
        refOneFirstName: '',
        refOneLastName: '',
        refOneRelationship: '',
        refOneEmail: '',
        refOneNumber: '',
        refTwoFirstName: '',
        refTwoLastName: '',
        refTwoRelationship: '',
        refTwoEmail: '',
        refTwoNumber: '',
        disclosure: '',
        concern: '',
        allegation: '',
        eligible: undefined,
        fullName: '',
        dateCompleted: ''
      },
      formSubmitted: false,
      formError: responseHelper.noError,
      successMessage: ''
    })

    const volunteer = () => {
      formState.formError = responseHelper.noError
      if (!formState.formSubmitted && !formState.successMessage) {
        formState.formSubmitted = true
        axios.post(
          'pub/volunteers',
          formState.volunteerForm
        ).then((response) => {
          if (response?.data?.success && response?.data?.result) {
            formState.successMessage = response.data.result.message
          } else {
            formState.formError = responseHelper.unexpectedResult
          }
        }).catch((error) => {
          formState.formError = responseHelper.getMessage(error)
        }).finally(() => {
          formState.formSubmitted = false
        })
      }
    }

    return {
      formState,
      volunteer
    }
  }
})
</script>
